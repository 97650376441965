/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './index.css';
import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';

const meterQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "meter" } }) {
      edges {
        node {
          siteTitle
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
            }
          }
          imageAlt
        }
      }
    }
    allFile(filter: { name: { regex: "/see|300meter/" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

const MeterCase = () => (
  <StaticQuery
    query={meterQuery}
    render={({ allStagesJson, allFile }) => {
      const AndroidUrl = 'https://play.google.com/store/apps/details?id=ch.maseai.meter&hl=de';
      const iOSUrl = 'https://itunes.apple.com/us/app/300meter/id1408620037?ls=1&mt=8';
      const stageData = allStagesJson.edges[0].node;
      const appImg = getImage(allFile.edges[0]?.node.childImageSharp);
      const seeImg = getImage(allFile.edges[1]?.node.childImageSharp);

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              gatsbyImageData: stageData.imageSrc?.childImageSharp?.gatsbyImageData,
              alt: stageData.imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData.title }} />}
          >
            <div>
              {stageData.contentBlocks.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
          </Stage>

          <CaseBlock
            title="300 Meter zum Ufer"
            subtitle="Hilfsmittel für Kapitäne, Sicherheitsabstand zum Ufer"
            graphic={<GatsbyImage image={seeImg} alt="See" />}
          >
            <p>
              Wer gerne mit dem Boot auf Schweizer Seen unterwegs ist, der kennt das Problem: Es ist sehr schwierig, die
              Distanz zum Ufer richtig einzuschätzen.
            </p>
            <p>
              Innere Uferzone (0 bis 150 m): Motorschiffe dürfen die innere Uferzone nur befahren, um an- oder abzulegen,
              stillzuliegen (ankern) oder Engstellen zu durchfahren; sie haben dazu den kürzesten Weg zu nehmen.
              Parallelfahrten zum Ufer sind untersagt.
            </p>
            <p>
              Äussere Uferzone (150 bis 300 m): Parallelfahrten zum Ufer sind gestattet. Die maximal erlaubte Geschwindigkeit
              beträgt 10 km/h.
            </p>
          </CaseBlock>
          <div className="container">
            <Facts title="Technologie">
              <ul>
                <li>Geschrieben in Javascript</li>
                <li>Umsetzung mit React Native und Google Maps Platform</li>
                <li>Continuous Integration mit GitLab CI und Docker</li>
              </ul>
            </Facts>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default MeterCase;
